import * as React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Typography } from '@mui/material';

import { SuccessBanner } from 'component-library/banners/SuccessBanner';
import { DteButton } from 'component-library/buttons/DteButton';
import { dayjsDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import Footer from 'component-library/footer/Footer';
import { serviceProtection } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTitles } from 'constants/';
import { useDunningLockDetails } from 'hooks';
import { googleTagManager } from 'services';
import { useResponsiveRendering, useResponsiveSx } from 'theme/breakpoints/responsiveHooks';
import { currentBillUrl } from 'urls-and-paths';

import {
  assistanceCopySx,
  assistanceInformationContainerSx,
  assistanceInformationContentSx,
  assistanceTitleSx,
  mainContentSx,
  rootContainerSx,
} from './assistanceComponents';
import { jLockViewCurrentBillClickEvent, jLockViewEvent } from './gtmEventsJLock';
import messages from './messages';

const JLock = (): React.ReactElement => {
  const { isDunningLockDetailsSuccess, dunningLockDetails } = useDunningLockDetails({ dunningLockReason: 'J' });
  const mobileHeader = <HeaderMobile titleText={serviceProtection} isHeaderButtonRendered={false} />;
  const desktopHeader = <HeaderDesktop titleText={serviceProtection} isHeaderButtonRendered={false} />;
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  const onPageViewHandler = (): void => {
    googleTagManager.trackViewEvent({
      ...jLockViewEvent,
      authenticationStatus: true,
    });
  };

  const viewCurrentBillOnClickHandler = (): void => {
    googleTagManager.trackButtonClick({
      ...jLockViewCurrentBillClickEvent,
      authenticationStatus: true,
    });
    window.location.href = currentBillUrl;
  };

  const mainContentResponsiveSx = useResponsiveSx(
    mainContentSx.mobile,
    mainContentSx.tablet,
    mainContentSx.desktop,
    mainContentSx.smallMobile,
  );

  const assistanceInformationContainerResponsiveSx = useResponsiveSx(
    assistanceInformationContainerSx.mobile,
    assistanceInformationContainerSx.tablet,
    assistanceInformationContainerSx.desktop,
    assistanceInformationContainerSx.smallMobile,
  );

  const assistanceInformationContentResponsiveSx = useResponsiveSx(
    assistanceInformationContentSx.mobile,
    assistanceInformationContentSx.tablet,
    assistanceInformationContentSx.desktop,
    assistanceInformationContentSx.smallMobile,
  );

  const lockEndDate: string = isDunningLockDetailsSuccess
    ? dayjsDate(dunningLockDetails[0].endDate).format('dddd, MMMM D, YYYY')
    : '';

  useEffect(() => {
    if (isDunningLockDetailsSuccess) {
      onPageViewHandler();
    }
  }, [isDunningLockDetailsSuccess, onPageViewHandler]);

  return (
    <>
      <Helmet>
        <title>{PageTitles.SERVICE_PROTECTION_J_LOCK}</title>
      </Helmet>
      <Box sx={rootContainerSx}>
        {pageHeader}
        <Box sx={mainContentResponsiveSx} role="main">
          {isDunningLockDetailsSuccess ? (
            <>
              <SuccessBanner message={`${messages.successBannerText} ${lockEndDate}.`} />
              <Box data-testid="assistance-information-container" sx={assistanceInformationContainerResponsiveSx}>
                <Box data-testid="assistance-information-content" sx={assistanceInformationContentResponsiveSx}>
                  <Typography sx={assistanceTitleSx}>{messages.assistanceTitle}</Typography>
                  <Typography sx={assistanceCopySx}>{messages.agencyText}</Typography>
                  <Typography sx={assistanceCopySx}>{messages.representativeText}</Typography>
                </Box>
              </Box>
              <DteButton variant="primary" text="View Current Bill" onClick={viewCurrentBillOnClickHandler} />
            </>
          ) : (
            <LoadingSpinner />
          )}
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default JLock;
